
    /deep/.el-breadcrumb__item .el-breadcrumb__inner{
        color: #303133;
    }
    .commodityManagement-table{
        /deep/th{
            padding: 0!important;
            height: 60px;
            line-height: 60px;
        }
        /deep/td{
            padding: 0!important;
            height: 60px;
            line-height: 60px;
        }
    }
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .commodityManagement{
        padding: 30px;
        height: 100%;
        .commodityManagement-header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 24px;
            .el-breadcrumb {
                line-height: 40px;
            }
            .newClientBtn{
                width: 120px;
                color: #ffffff;
                background: #1122D8;
                border-radius:4px;
                border: none;
                margin-left: 10px;
            }
        }
    }
